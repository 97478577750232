<template>
  <div class="pagination">
            <el-pagination background
             layout=" pager,slot"
             @current-change="getCurrentChange"
                :current-page="pageNum" :total="total" :page-size="pageSize"
                >
                <template #default>
                     <el-input style="width: 50px;height: 32px;"  v-model="inputVal" @change="onchange" class="pageInput"></el-input>
                    <span class="toPage">Go</span>
                </template>
             </el-pagination>

    </div>
</template>

<script>
export default {
  name: 'pagination',
  components: {},
  props:{
    total:{
      type:Number,
      default:0
    },
    pageNum:{
      type:Number,
      default:1
    },
  },
  data() {
    return {
      pageSize: 10,
      inputVal: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    getCurrentChange(val){
      this.$emit('pagination', val);
    },
    onchange(val){
      const reg = /^[0-9]*$/
    if(!reg.test(val)) return this.inputVal = ''

    this.$emit('pagination', val);
    this.inputVal = ''

    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination {
    width: 100%;
    height: 50px;
    margin: 50px 0;
  }
::v-deep .el-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
  /* 修改选中页码的背景颜色 */
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2e2e2e;
  color: #1DBF73;
  height: 32px;
    width: 32px;
}

/* 修改未选中页码的背景颜色 */
::v-deep .el-pagination .number:not(.active) {
  background-color: #161616;
  color: #B3B3B3;
  height: 32px;
  width: 32px;
}
/* 修改鼠标悬停在页码上时的背景颜色 */
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #161616;
  color: #B3B3B3;
}


::v-deep .el-pagination.is-background .el-pager li {
  background-color: #161616;
}

.toPage {
  width: 20px;
  height: 19px;
  font-size: 14px;
  color: #fff;
}
::v-deep .el-input__inner {
  background-color: #161616;
  border: solid 1px #707070;
}
::v-deep .el-input__inner{
  height: 32px;
}
@media only screen and (max-width: $mobile-width) {
  .pagination {
    margin: .3rem 0;
  }
}
</style>
