<template>
  <div class="newsTags">
      <div class="current">当前位置</div>
      <div class="home" @click="goHome">首页</div>
      <span class="iconfont iconxia"></span>
      <div class="home" @click="goNews">新闻资讯</div>
      <span class="iconfont iconxia"></span>
      <div class="home green" >{{ $route.query.type }}</div>
  </div>
</template>

<script>
export default {
  name: 'tags',
  components: {},
  props: {

  },
  data() {
    return {

    }
  },
  created() {},
  mounted() {
  },
  methods: {
    goNews(){
      this.$router.push({
        path: "/news/home",
      })
    },
    goHome(){
      this.$router.push({
        path: "/",
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.newsTags{
  width: 100%;
  height: 32px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  text-align: center;
  .current {
    width: 76px;
    height: 100%;
    line-height: 32px;
    background-color: #1dbf73;
  }
  .home {
    height: 19px;
    margin-left: 10px;
    cursor: pointer;
  }
  span {
    margin-left: 8px;
    transform: rotate(-90deg);
  }
  .green {
    color: #1DBF73;
  }
}
@media only screen and (max-width: $mobile-width) {
  .newsTags{
    margin-top: .6rem;
    font-size: .1833rem;
    .current {
    width: 1.1667rem;
  }
  .home {
    height: .7917rem;
    display: flex;
    align-items: center;
    margin-left: .2167rem;
    white-space: nowrap;
  }

  .iconfont {
    font-size: .1rem;
    margin-left: .3333rem !important;
  }
  }
}
</style>
