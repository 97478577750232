<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2021-12-14 14:59:11
 * @LastEditors: Please set LastEditors
 * @Description: 新闻动态详情
 * @FilePath: \new-website-1123\src\views\service\joinus\index.vue
-->
<template>
  <div class="news-details">
    <tags></tags>
    <w-title
            :model="$route.query.type.slice(0,2)"
            :modelkey="$route.query.type.slice(2,4)"
            direction="left"
            :isDetails="false"
            class="title"
></w-title>
<div class="industry">
    <ul class="news-home-ul" >
      <li class="outer_clear" >
        <div class="news-home-ul-text animation-3">

          <div
            class="news-home-ul-article animation-3"
            v-for="item in newList"
            :key="item.id"
            @click="goNewsDetails(item.id)"
          >
            <div class="news-home-ul-article-img">
              <img :src="item.imageUrl" />
            </div>
            <div class="news-home-ul-article-info">
              <div class="news-home-ul-article-time">
                  <span class="year">{{ item.createTime.slice(0,4) }}</span>
                  <div>
                    <span class="month">{{ item.createTime.slice(5,7) }}/{{ item.createTime.slice(8,10) }}</span>
                  <span class="day">{{ item.createTime.slice(11,16) }}</span>
                  </div>
              </div>
              <h2>{{ item.name }}</h2>
              <h4>{{ clearHtml(item.info)}}</h4>
              <!-- <h4>{{item.info}}</h4> -->
              <img
                class="ml10"
                width="40"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              />

            </div>

          </div>
        </div>

      </li>
    </ul>
   </div>
   <pagination @pagination="handleCurrentChange"  :pageNum="page.pageNum" class="page" :total="total"></pagination>
  </div>
</template>

<script>
import {getNesListApi} from '@/api/index.js'
import {clearHtml} from '@/utils/removeTag.js'
import tags from '../components/tags.vue'
import wTitle from "@/components/sTitle/viewtitle";
import pagination from "@/components/pagination/index.vue";
export default {
  name: "news-details",
  components: {tags,wTitle,pagination},
  data() {
    return {
      page:{
        pageNum:1,
        pageSize:10
      },
      total:0,
      newList: [ ],
    };
  },
  created() {this.getNewsData();},
  computed: {

  },
  mounted() {
  },
  methods: {

    /**
     * @description: 分页
     */
    handleCurrentChange(val){
      this.page.pageNum = val;
      this.getNewsData()
    },
    goNewsDetails(id) {
      this.$router.push({
        path:"/news/details",
        query:{
          id:id
        }
      });
    },
    /**
     * @description: 获取新闻列表
     */
    async getNewsData(){
     await getNesListApi({
      ...this.page,
      type: `${this.$route.query.type}`,
      status:1
     }).then(res=>{
        this.newList = res.data.data.list
        this.total = res.data.data.total;
      })
    },
    /**
     * @description: 清除html标签
     */

  clearHtml(text){
    return clearHtml(text)
  }

  },
};
</script>

<style lang="scss" scoped>
.news-details{
  padding: 0 10.8333vw;
  .title {
    margin:2.0833vw 0;
  }
  .news-home-ul {
  color: $color-text;

  li {

      .news-home-ul-article {
        color: rgba(255, 255, 255, 0.4);
        width: 78.3333vw;
        height: 15.625vw;
        min-height: 150px !important;
        margin-bottom: 1.25vw;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        .ml10 {
          position: absolute;
          bottom: .7813vw;
          right: 1.0417vw;
          display: none;
        }
        &:hover {
          background-color: #172820;
          .ml10 {
            display: block;
          }
          .news-home-ul-article-img {
            img {
              transform: scale(1.1);
            }
          }
        }

        .news-home-ul-article-img {
          width: 20.8333vw;
          height: 100%;
          display: block;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.4s ease-in-out 0s; // 动画
          }
        }

        .news-home-ul-article-info {
          width: 78.3333vw;
          height: 100%;
          padding-left: 2.0833vw;
          padding-bottom: 1.3021vw;
          .news-home-ul-article-time {
            width: 5.8333rem;
            height: 2.6667rem;
            min-height: 3vw !important;
            display: flex;
            margin-top: 1.3021vw;
            align-items: center;
            .year {
              width: 21px;
              height: 40px;
              background-color: #1DBF73;
              writing-mode:vertical-rl; // 竖排文字
              transform: rotate(180deg);
              text-align: center;
              line-height: 21px;
              font-size: 16px;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              margin-left: 8px;
              justify-content: space-between;
             .month  {
              width: 71px;
              height: 31px;
              font-size: 24px;
              color: #FFFFFF;
            }
            .day {
              width:50px;
              height: 24px;
              font-size: 18px;
              color: #B3B3B3;
            }
            }

          }
          h2 {
            height: 1.6146vw;
            color: #FFFFFF;
            font-size: 1.25vw;
            margin-top: 1.0417vw;
            margin-bottom: 1.25vw;
            overflow: hidden;text-overflow: ellipsis;
          }
        }

        h4 {
          font-size: .7292vw;
          margin-bottom: 1.3542vw;
          width: 100%;
          font-weight: normal;
          color: #B3B3B3;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          text-overflow: ellipsis;

        }
        .news-home-more {
          height: 0;
          display: block;

          overflow: hidden;
          color: $color-active;
        }

      }
    .news-home-ul-img {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.page {
  text-align: center;
}
}
@media only screen and (max-width: $mobile-width)  {

  .news-details {
    .industry {
      .news-home-ul-text {
        .news-home-ul-article {
        color: rgba(255, 255, 255, 0.4);
        width: 100%;
        height: 1.6679rem;
        min-height: 85px !important;
        margin-bottom: .2202rem;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .ml10 {
          position: absolute;
          bottom: .0688rem;
          right: .0917rem;
          display: none;
        }
     &:hover {
      background-color: #161616;
      .ml10{
        display: none;
      }
        }

        .news-home-ul-article-img {
          width: 1.8349rem;
          height:100%;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .news-home-ul-article-info {
          width: 100%;
          height: 100%;
          padding-left: .1835rem;
          padding-bottom: .156rem;
          .news-home-ul-article-time {
            margin-top: 10px;
            width: .6422rem;
            height: .2936rem;

            display: flex;
            align-items: center;
            .year {
              width: .1835rem;
              height: .5505rem;
              background-color: #1DBF73;
              writing-mode:vertical-rl; // 竖排文字
              transform: rotate(180deg);
              text-align: center;
              line-height: .1835rem;
              font-size: .1468rem;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              margin-left: .0367rem;
              justify-content: space-between;
             .month  {
              width: 34.9999px;
              height:14.9984px;
              font-size: 12.0009px;
              color: #FFFFFF;
            }
            .day {
              width: 25.0046px;
              height: 12.0009px;
              font-size: 9.0034px;
              color: #B3B3B3;
            }
            }

          }
          h2 {
            height: 14.9984px;
            color: #FFFFFF;
            font-size: .1101rem;
            margin-top: .1835rem;
            margin-bottom: .1101rem;
            overflow: hidden;text-overflow: ellipsis;
          }
        }

        h4 {
          font-size: .15rem;
          margin-bottom: 13px;
          width: 100%;
          font-weight: normal;
          color: #B3B3B3;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
        }
        .news-home-more {
          height: 0;
          display: block;
          overflow: hidden;
          color: $color-active;
        }

      }
      }
    }
  }

}


@media only screen and (max-width: 1400px){

h4 {

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5 !important;
        text-overflow: ellipsis;
      }
}
@media only screen and (max-width: 1200px){

h4 {

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4 !important;
        text-overflow: ellipsis;
      }
}
@media only screen and (max-width: 1000px){

h4 {

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3 !important;
        text-overflow: ellipsis;
      }
}
@media only screen and (max-width: 500px){

h4 {

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        text-overflow: ellipsis;
      }
}
</style>
