
   export     function clearHtml(html) {
    const strippedTags = html.replace(/(<([^>]+)>)/gi, '');
    const strippedEntities = strippedTags.replace(/&nbsp;|&#160;|&lt;|&gt;|&ldquo;|&amp;|&rdquo;|&lsquo;|&rsquo;|&middot;|&mdash;/g, (match) => {
      switch (match) {
        case '&nbsp;':
        case '&#160;':

          return ' ';
        case '&lt;': return '<';
        case '&gt;': return '>';
        case '&amp;': return '&';
        case '&middot;': return '-';
        case '&mdash;': return '—';

        case '&lsquo;': return '‘';
        case '&rsquo;': return '’';
        case '&ldquo;': return '“';
        case '&rdquo;': return '”';
        default: return '';
      }
    });
  const strippedSpaces = strippedEntities.replace(/\s+/g, ' ');
  return strippedSpaces.trim();
}
